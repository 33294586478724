/* eslint-disable */
import style from "./MentionsLegales.module.scss";
import { useEffect } from "react";

export const MentionsLegales = () => {
  useEffect(() => {
    document.title = "Mentions Légales - CoachingHub";
  }, []);

  return (
    <main className={style["MentionsLegales"]}>
      <section>
        <h1>Mentions légales</h1>
        <h2>Collecty&apos;form</h2>
        <div className={style["content"]}>
          <h2>
            En vertu de l’article 6 de la Loi n°2004-575 du 21 juin 2004 pour la
            confiance dans l’économie numérique, il est précisé aux utilisateurs
            du site www.collecty-space.fr l’identité des différents intervenants
            dans le cadre de sa réalisation et de son suivi :
          </h2>
          <div>
            <ul>
              <li>Nom du site web : CoachingHub</li>
              <li>Adresse : https://www.collecty-space.fr</li>
              <li>
                Propriétaire : Collecty’form, société par actions simplifiée au
                capital de 3.000 €, dont le siège social est situé 22 rue Gustave Eiffel, 78300 Poissy 
                immatriculée sous le numéro 915 340 210
              </li>
              <li>
                Adresse email ou numéro de téléphone du contact :
                contact@collecty-form.fr
              </li>
              <li>Responsable de publication : Milo ROCHE-VANDENBROUCQUE</li>
              <li>
                Conception et réalisation : Milo ROCHE-VANDENBROUCQUE, William
                BANAS, Quentin DARTY
              </li>
              <li>
                Le site est hébergé par la société HOSTINGER INTERNATIONAL LTD,
                61 Lordou Vironos Street, 6023 Larnaca, Chypre.
              </li>
            </ul>
          </div>
          <h2>Conditions d'utilisation</h2>
          <div>
            L’utilisation du présent site implique l’acceptation pleine et
            entière des conditions générales d’utilisation décrites ci-après.
            Ces conditions d’utilisation sont susceptibles d’être modifiées ou
            complétées à tout moment.
          </div>
          <h2>Informations</h2>
          <div>
            Les informations et documents du site sont présentés à titre
            indicatif, sans de caractère exhaustif, et ne peuvent engager la
            responsabilité du propriétaire du site. Il ne pourra pas être tenu
            responsable des omissions, inexactitudes, et des carences dans la
            mise à jour de ces informations. Le propriétaire du site ne peut
            être tenu responsable des dommages directs et indirects consécutifs
            à l’accès au site.
          </div>
          <h2>Interactivité</h2>
          <div>
            Les utilisateurs du site ont accès à leur historique et sont libres
            de le partager. Le propriétaire du site se réserve néanmoins le
            droit de retirer sans préavis et sans justification tout contenu
            déposé par les utilisateurs qui ne satisferait pas à la charte
            déontologique du site ou à la législation en vigueur.
          </div>
          <h2>Propriété intellectuelle</h2>
          <div>
            Sauf mention contraire, tous les éléments accessibles sur le site
            (textes, images, graphismes, logo, icônes, sons, etc.) restent la
            propriété exclusive de Collecty’form, en ce qui concerne les droits
            de propriété intellectuelle, à savoir les droits d’auteur, de
            marque, brevet et/ou dessins et modèle, ou les droits d’usage.
          </div>
          <div>
            Toute reproduction, représentation, modification, publication,
            adaptation de tout ou partie des éléments du site, quel que soit le
            moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
            préalable de Collecty’form.
          </div>
          <div>
            Toute exploitation non autorisée du site ou de l’un quelconque des
            éléments qu’il contient est considérée comme constitutive d’une
            contrefaçon et passible de poursuites judiciaires.
          </div>
          <div>
            Les marques et logos reproduits sur le site sont déposés par les
            sociétés qui en sont propriétaires.
          </div>
          <div>
            L’Utilisateur est seul responsable du contenu utilisateur qu’il met
            en ligne via le Site, ainsi que des textes et/ou opinions qu’il
            formule. A ce titre, il garantit le service contre tous recours,
            fondés directement ou indirectement sur ces propos et/ou données,
            susceptibles d’être intentés par quiconque à l’encontre du Site. Il
            s’engage en particulier à prendre en charge le paiement des sommes,
            quelles qu’elles soient, résultant du recours d’un tiers à
            l’encontre du Site, y compris les honoraires d’avocat et frais de
            justice.
          </div>
          <div>
            Collecty’form se réserve le droit de supprimer tout ou partie du
            contenu utilisateur, à tout moment et pour quelque raison que ce
            soit, sans avertissement ou justification préalable. L’Utilisateur
            ne pourra faire valoir aucune réclamation à ce titre.
          </div>
          <h2>Collecte de données</h2>
          <div>
            Le règlement général sur la protection des données (RGPD) est un
            règlement qui renforce et unifie la protection des données pour tous
            les individus au sein de l'Union européenne (UE). Nous ne collectons
            aucune donnée personnelle supplémentaire à celles explicitement
            demandées et renseignées par l'utilisateur lors de son inscription.
          </div>
          <h2>Données personnelles</h2>
          <div>
            Il est obligatoire qu'au moment de votre inscription, ou lors de
            tout changement apporté à votre Pseudo, vous ne renseigniez pas de
            données personnelles. Il ne doit pas être possible pour une personne
            extérieure à la plateforme de vous identifier. (Pas de mail, pas de
            nom de famille...)
          </div>
          <div>
            Les données personnelles collectées sur le site sont les suivantes :
            Prénom, Pseudo, Tranche d'âge, et toute inscription / présence ou
            absence à une séance de coaching organisée.
          </div>
          <div>
            Les seules personnes pouvant prétendre à un accès total à ces
            données sont le DSI de Collecty'form : Milo ROCHE-VANDENBROUCQUE,
            dans le cadre de la maintenance du site. Le responsable QVT au sein
            de la Préfecture de Police de Paris dispose également d'un accès
            aménagé à ces données, dans le cadre de la gestion des séances de
            coaching.
          </div>
          <div>
            Les données personnelles sont notamment protégées par la loi n°
            78-87 du 6 janvier 1978, le Règlement 2016/79 du 27 avril 2016
            relatif à la protection des personnes physiques à l’égard du
            traitement des données à caractère personnel et à la libre
            circulation des données (Règlement Général sur la protection des
            Données RGPD), ainsi que par la loi n° 2004-801 du 6 août 2004, et
            l’article 226- 13 du Code pénal.
          </div>
          <div>
            La durée de conservation des données personnelles est identique à
            celle de la durée d’inscription de l’utilisateur, soit 16 semaines à
            compter de la première séance de coaching organisée. Dès lors qu’un
            utilisateur est supprimé ou se désinscrit, toute donnée directement
            liée à celui-ci est supprimée définitivement.
          </div>
          <div>
            Tout utilisateur peut demander à tout moment le retrait de ses
            données personnelles renseignées sur la plateforme.
          </div>
          <div>
            Tout utilisateur peut demander à tout moment la suppression de ses
            données personnelles, entrainant la suppression de son compte.
          </div>
          <div>
            En tout état de cause, Collecty’Form ne collecte des informations
            personnelles relatives à l’utilisateur que pour le besoin de
            certains services proposés par le Site. L’utilisateur fournit ces
            informations en toute connaissance de cause, notamment lorsqu’il
            procède par lui-même à leur saisie. Il est alors précisé à
            l’utilisateur du Site l’obligation ou non de fournir ces
            informations.
          </div>
          <div>
            Conformément aux dispositions des articles 49 et suivants de la loi
            78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
            aux libertés, ainsi que les articles 15 et suivants du RGPD, tout
            utilisateur dispose d’un droit d’accès, de rectification,
            d’effacement et d’opposition aux données personnelles le concernant,
            en effectuant sa demande écrite et signée, par courriel à l’adresse
            : contact@collecty-form.fr
          </div>
          <div>
            Aucune information personnelle de l’utilisateur du Site n’est
            publiée à l’insu de l’Utilisateur, échangée, transférée, cédée ou
            vendue sur un support quelconque à des tiers. Seule l’hypothèse du
            rachat de Collecty’Form et de ses droits permettrait la transmission
            desdites informations à l’éventuel acquéreur qui serait à son tour
            tenu de la même obligation de conservation et de modification des
            données vis à vis de l’utilisateur du site.
          </div>
          <h2>Liens</h2>
          <h3>Liens sortants</h3>
          <div>
            Le propriétaire du site décline toute responsabilité et n’est pas
            engagé par le référencement via des liens hypertextes, de ressources
            tierces présentes sur le réseau Internet, tant en ce qui concerne
            leur contenu que leur pertinence.
          </div>
          <h3>Liens entrants</h3>
          <div>
            Le propriétaire du site autorise les liens hypertextes vers l’une
            des pages de ce site, à condition que ceux-ci ouvrent une nouvelle
            fenêtre et soient présentés de manière non équivoque afin d’éviter :
            Tout risque de confusion entre le site citant et le propriétaire du
            site ainsi que toute présentation tendancieuse, ou contraire aux
            lois en vigueur.
          </div>
          <div>
            Le propriétaire du site se réserve le droit de demander la
            suppression d’un lien s’il estime que le site source ne respecte pas
            les règles ainsi définies.
          </div>
          <h2>Cookies</h2>
          <div>
            La navigation sur le site https://collecty-space.fr/ ne provoque pas
            l’installation de cookie(s) sur l’ordinateur de l’utilisateur.
            Token (Propre à la connexion à la plateforme et au temps nécessaire avant reconnexion, conservé le temps de la session utilisateur)
            user_id (identification de l’utilisateur lors de l’utilisation de la plateforme, conservé jusqu’à déconnexion de ce premier)
            user_role (identification des droits de l’utilisateurs, conservé jusqu’à déconnexion de celui-ci
          </div>
          <h2>Limitation de responsabilité</h2>
          <div>
            Les informations diffusées sur le Site proviennent de sources
            réputées fiables. Toutefois, le Site ne peut garantir l’exactitude
            ou la pertinence de ces données. En outre, les informations mises à
            disposition sur ce Site le sont uniquement à titre informatif et ne
            sauraient constituer en aucun cas un conseil ou une recommandation
            de quelque nature que ce soit. En conséquence, l’utilisation des
            informations et contenus disponibles sur l’ensemble du Site, ne
            saurait engager la responsabilité de Collecty’Form à quelque titre
            que ce soit. L’Utilisateur est seul maître de la bonne utilisation,
            avec discernement et esprit, des informations mises à sa disposition
            sur le Site.
          </div>
          <div>
            Collecty’Form ne pourra être tenu responsable des dommages et/ou
            préjudices directs et indirects, matériels ou immatériels, ou de
            quelque nature que ce soit, causés à l’utilisateur, lors de l’accès
            au Site, et résultant de l’utilisation du Site ou d’un matériel dont
            le navigateur n’est pas mis à jour ou infecté d’un virus, de
            l’apparition d’un bug ou d’une incompatibilité, ou encore de
            l’indisponibilité du Site. Le terme « Utilisation » doit être
            entendu au sens large, c’est-à-dire tout usage du Site quel qu’il
            soit, licite ou non.
          </div>
          <div>
            Le Site s’engage néanmoins à mettre en place tous les moyens
            nécessaires pour garantir la sécurité et la confidentialité des
            données transmises. Toutefois, l’Utilisateur admet connaître les
            limitations et contraintes propres au réseau internet et, à ce
            titre, reconnaît notamment l’impossibilité d’une garantie totale de
            la sécurisation des échanges de données.
          </div>
          <div>
            La responsabilité du Service ne pourra être engagée en cas de force
            majeure ou de faits indépendants de sa volonté.
          </div>
          <h2>Confidentialité</h2>
          <div>
            Tout utilisateur dispose d’un droit d’accès, de rectification,
            d’opposition et d’effacement des données personnelles le concernant,
            en effectuant sa demande écrite et signée, accompagnée d’une preuve
            d’identité.
          </div>
          <h2>Crédits</h2>
          <div>
            <b>Collecty’form</b>, société par actions simplifiée au capital de
            3.000 €, dont le siège social est situé 22 rue Gustave Eiffel, 78300 Poissy, immatriculée sous le numéro 915 340 210 Responsable de
            publication : Milo ROCHE-VANDENBROUCQUE
          </div>
        </div>
      </section>
    </main>
  );
};
