// InfoBanner.jsx
import style from "./InfoBanner.module.scss";

const InfoBanner = () => {
  return (
    <div className={style["info-banner"]}>
      <p>
        📢 Information : Cette plateforme peut présenter des bogues. Au besoin,
        n&apos;hésitez pas à{" "}
        <a href="mailto:contact@collecty-form.fr?subject=Coaching Hub - Support">nous contacter</a> ! 📧
      </p>
    </div>
  );
};

export default InfoBanner;
